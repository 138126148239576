import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const topBtn = () => {
    const btn = document.querySelector('.topbtn')
    btn.addEventListener("click", () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        })
    })

    window.addEventListener("scroll", function () {
        const scroll = window.scrollY;

        if (scroll > 1200) {
            btn.style.opacity = "1"
            btn.style.zIndex = "99"
            btn.addEventListener('mouseover', function(){
                btn.style.opacity = ".5"
            })

        } else {
            btn.style.opacity = "0"
            btn.style.zIndex = "-1"
        }
    })
};

export default topBtn;